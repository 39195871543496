.manage {
  .content {
    @apply text-white py-10 flex flex-col mx-auto;
    max-width: 620px;
    min-height: calc(100vh - 10rem);

    &__box {
      @apply p-4 rounded-lg w-full mt-10;
      background: #2d2e35;

      &__stats {
        @apply flex text-sm font-medium text-grey300 border-grey750 border border-solid rounded-lg p-1;
      }

      &__body {
        @apply mx-2 mt-2 border border-solid rounded-lg border-innerBorder flex flex-col items-center gap-8 py-12;
        img {
          width: 158px;
          height: 80px;
        }
      }
    }
  }
}
